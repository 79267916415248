import React, { useState } from "react";
import styled from "styled-components";
import Particles from "react-particles-js";

import LoginForm from "../components/LoginForm";
import RegisterForm from "../components/RegisterForm";

import { ALT_BLACK } from "../theme/colors";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 3rem;
  color: ${ALT_BLACK};
`;
const Title = styled.h1`
  font-size: 5rem;
  margin: 0px;
`;
const Subtitle = styled.h2`
  margin-top: 0px;
`;
const SwitchContainer = styled.div`
  width: 360px;
  display: flex;
  justify-content: space-between;
  background-color: white;
  z-index: 1;
`;
const ActionButton = styled.div`
  height: 60px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  ${(props) =>
    props.active
      ? `
        border-radius: 50px;
        background: #ffffff;
        box-shadow: inset 5px 5px 10px #cccccc, 
        inset -5px -5px 10px #ffffff;
        `
      : `
        justify-content: center;
        border-radius: 50px;
        box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #ffffff;
        `}
`;
const AuthContainer = styled.div`
  height: 460px;
  width: 500px;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.5rem;
  background-color: white;
  z-index: 1;
`;
const ParticlesContainer = styled.div`
  width: 100%;
  height: 460px;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 0;
`;

function Auth() {
  const [onLogin, setOnLogin] = useState(true);
  return (
    <PageContainer>
      <TitleContainer>
        <Title>杭州</Title>
        <Subtitle>hangzhou:ist</Subtitle>
      </TitleContainer>
      <SwitchContainer>
        <ActionButton left active={onLogin} onClick={() => setOnLogin(true)}>
          Login
        </ActionButton>
        <ActionButton right active={!onLogin} onClick={() => setOnLogin(false)}>
          Sign up
        </ActionButton>
      </SwitchContainer>
      <AuthContainer onLogin={onLogin}>
        {onLogin && <LoginForm />}
        {!onLogin && <RegisterForm />}
      </AuthContainer>

      <ParticlesContainer>
        <Particles
          style={{ height: "100%" }}
          params={{
            particles: {
              number: {
                value: 80,
                density: {
                  enable: true,
                  value_area: 1900,
                },
              },
              color: {
                value: "#253136",
              },
              shape: {
                type: "circle",
                stroke: {
                  width: 0,
                  color: "#000000",
                },
                polygon: {
                  nb_sides: 5,
                },
              },
              opacity: {
                value: 2,
                random: true,
                anim: {
                  enable: true,
                  speed: 1,
                  opacity_min: 0,
                  sync: false,
                },
              },
              size: {
                value: 3,
                random: true,
                anim: {
                  enable: false,
                  speed: 4,
                  size_min: 0.3,
                  sync: false,
                },
              },
              line_linked: {
                enable: false,
              },
              move: {
                enable: true,
                speed: 1,
                direction: "bottom",
                random: true,
                straight: false,
                out_mode: "out",
                bounce: false,
                attract: {
                  enable: false,
                  rotateX: 600,
                  rotateY: 600,
                },
              },
            },
            interactivity: {
              detect_on: "canvas",
              events: {
                onhover: {
                  enable: false,
                  mode: "bubble",
                },
                onclick: {
                  enable: false,
                  mode: "repulse",
                },
                resize: true,
              },
              modes: {
                grab: {
                  distance: 400,
                  line_linked: {
                    opacity: 1,
                  },
                },
                bubble: {
                  distance: 250,
                  size: 0,
                  duration: 2,
                  opacity: 0,
                  speed: 3,
                },
                repulse: {
                  distance: 400,
                  duration: 0.4,
                },
                push: {
                  particles_nb: 4,
                },
                remove: {
                  particles_nb: 2,
                },
              },
            },
            retina_detect: true,
          }}
        />
      </ParticlesContainer>
    </PageContainer>
  );
}

export default Auth;
