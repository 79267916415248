import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Particles from "react-particles-js";

import { BLACK } from "../theme/colors";

const PageContainer = styled.div`
  overflow: hidden;
  padding: 16px 16px;
`;
const TextContainer = styled.div`
  margin-top: 10rem;
`;
const Title = styled.h1`
  font-size: 9.5rem;
  color: ${BLACK};
`;
const Subtitle = styled.h3`
  font-size: 4.5rem;
  color: ${BLACK};
`;
const LinkContainer = styled.div`
  position: absolute;
`;
const ParticlesContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
`;

function NoPage() {
  return (
    <PageContainer>
      <ParticlesContainer>
        <Particles
          style={{ height: "100%" }}
          params={{
            particles: {
              number: {
                value: 80,
                density: {
                  enable: true,
                  value_area: 1900,
                },
              },
              color: {
                value: "#253136",
              },
              shape: {
                type: "circle",
                stroke: {
                  width: 0,
                  color: "#000000",
                },
                polygon: {
                  nb_sides: 5,
                },
              },
              opacity: {
                value: 2,
                random: true,
                anim: {
                  enable: true,
                  speed: 1,
                  opacity_min: 0,
                  sync: false,
                },
              },
              size: {
                value: 3,
                random: true,
                anim: {
                  enable: false,
                  speed: 4,
                  size_min: 0.3,
                  sync: false,
                },
              },
              line_linked: {
                enable: false,
              },
              move: {
                enable: true,
                speed: 1,
                direction: "bottom",
                random: true,
                straight: false,
                out_mode: "out",
                bounce: false,
                attract: {
                  enable: false,
                  rotateX: 600,
                  rotateY: 600,
                },
              },
            },
            interactivity: {
              detect_on: "canvas",
              events: {
                onhover: {
                  enable: false,
                  mode: "bubble",
                },
                onclick: {
                  enable: false,
                  mode: "repulse",
                },
                resize: true,
              },
              modes: {
                grab: {
                  distance: 400,
                  line_linked: {
                    opacity: 1,
                  },
                },
                bubble: {
                  distance: 250,
                  size: 0,
                  duration: 2,
                  opacity: 0,
                  speed: 3,
                },
                repulse: {
                  distance: 400,
                  duration: 0.4,
                },
                push: {
                  particles_nb: 4,
                },
                remove: {
                  particles_nb: 2,
                },
              },
            },
            retina_detect: true,
          }}
        />
      </ParticlesContainer>
      <TextContainer>
        <Title>404</Title>
        <Subtitle>sorry that page does not compute.</Subtitle>
        <LinkContainer>
          <Link to='/'>
            <p>back to safety.</p>
          </Link>
        </LinkContainer>
      </TextContainer>
    </PageContainer>
  );
}

export default NoPage;
