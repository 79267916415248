import React from "react";
import styled from "styled-components";

import {
  WHITE as BG_COLOR,
  SHADOW,
  ALT_BLACK,
  BLACK as TEXT_COLOR,
} from "./colors";

const NeoMorphicStyledInput = styled.input`
  height: 35px;
  width: 100%;
  border: 0;
  outline: 0;
  font-size: 16px;
  font-weight: 550;
  color: ${TEXT_COLOR};
  border-radius: 50px;
  padding: 16px 18px;
  /*text-shadow: 1px 1px;*/
  background-color: ${BG_COLOR};
  box-shadow: inset 5px 5px 10px ${SHADOW}, inset -5px -5px 10px #fff;
  transition: all 0.2s ease-in-out;
  appearance: none;

  :focus {
    box-shadow: inset 2px 2px 4px ${SHADOW}, inset -2px -2px 4px #fff;
  }
`;

export const NeoMorphicInput = ({ field, form, ...props }) => {
  return <NeoMorphicStyledInput {...field} {...props} />;
};

export const InputContainer = styled.div`
  height: 110px;
  width: 350px;
  padding: 0px 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
`;

export const LabelContainer = styled.div`
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 600;
  color: ${ALT_BLACK};
`;

export const ErrorMsgContainer = styled.div`
  margin-top: 5px;
  color: red;
`;
