import React from "react";
import styled from "styled-components";

import { WHITE } from "../theme/colors";

// Component for adding navbar, footer etc.
// Stuff that stays pretty much the same through the app

const Body = styled.body`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${WHITE};
`;

function PageContainer(props) {
  return <Body>{props.children}</Body>;
}

export default PageContainer;
