import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import PageContainer from "./components/PageContainer";
import LandingPage from "./pages/LandingPage";
import AuthPage from "./pages/Auth";
// 404
import NotFound from "./pages/404";

function App() {
  return (
    <Router>
      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <PageContainer>
        <Switch>
          <Route exact path='/'>
            <LandingPage />
          </Route>
          <Route path='/auth'>
            <AuthPage />
          </Route>
          <Route path='*'>
            <NotFound />
          </Route>
        </Switch>
      </PageContainer>
    </Router>
  );
}

export default App;
