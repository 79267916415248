import React from "react";
import styled from "styled-components";

import { ALT_BLACK as BLACK } from "../theme/colors";

const NeuMorphContainer = styled.div`
  height: 35rem;
  width: 40rem;
  margin: auto;
  margin-top: 10rem;
  border-radius: 50px;
  background: #ffffff;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0;
`;
const Title = styled.h1`
  font-size: 10rem;
  color: ${BLACK};
  margin-top: 0;
  margin-bottom: 0;
`;
const Subtitle = styled.h2`
  font-size: 3rem;
  color: ${BLACK};
  margin-top: 0;
`;

function LandingPage() {
  return (
    <NeuMorphContainer>
      <Title>杭州</Title>
      <Subtitle>hangzhou:ist</Subtitle>
    </NeuMorphContainer>
  );
}

export default LandingPage;
