import React from "react";
import styled from "styled-components";

import { WHITE as BG_COLOR, SHADOW } from "../theme/colors";

const NeoMorphicStyledButton = styled.button`
  width: ${(props) => (props.width ? props.width + "px" : "100%")};
  border: 0;
  outline: 0;
  font-size: 18px;
  border-radius: 320px;
  padding: 16px;
  color: #61677c;
  font-weight: 600;
  text-shadow: 1px 1px;
  cursor: pointer;
  background-color: ${BG_COLOR};
  box-shadow: inset -5px -5px 20px #fff, 2px 2px 5px ${SHADOW};
  transition: all 0.2s ease-in-out;
  appearance: none;
  -webkit-appearance: none;

  :hover {
    box-shadow: -4px -4px 10px #fff, 4px 4px 10px ${SHADOW};
  }

  :active {
    box-shadow: inset 1px 1px 2px ${SHADOW}, inset -1px -1px 2px #fff;
  }
`;

function Button(props) {
  return <NeoMorphicStyledButton {...props} />;
}

export default Button;
